.bannerBg {
  // background-image: url('../assets/img/index/banner.png');
  // background-repeat: no-repeat;
  // background-position: 100% 100%;
  height: 758px;
  position: relative;
}
.bgWrap {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  & img {
    width: 100%;
    height: 100%;
  }
}

.formTip {
  height: 100%;
  padding-top: 120px;
  text-align: left;
}

.loginForm {
  // position: absolute;
  // top: 30px;
  // right: 0;
  width: 590px;
  background: #fff;
  border-radius: 4px;
  min-height: 200px;
  padding: 0 100px;
  padding-bottom: 70px;
}

.banner_wrap {
  width: 100%;
  height: 100%;
  >div {
    height: 100%;
  }
}

.login_button {
  width: 100%;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.imgCode {
  width: 100%;
  height: 40px;
}

.top_25 {
  margin-top: -20px;
}

.contact_wrap {
  position: fixed;
  top: 40%;
  right: 0;
  display: flex;
  align-items: center;
  &:hover {
    .contact_info {
      opacity: 1;
    }
  }
}

.contact {
  background: var(--main-color);
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.contact_txt {
  margin: 0 auto;
  word-wrap: break-word;
  /*英文的时候需要加上这句，自动换行*/
  word-break: break-all;
  color: #fff;
  text-align: center;
  font-size: 12px;

}

.contact_info {
  padding: 11px 20px;
  background-image: url('../assets/img/contract_bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  transition: all .5s ease-in-out;
  opacity: 0;
}
.applyBg {
  width: 100%;
  height: 267px;
  background-image: url('../assets/img/index/applyBgImg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.applyItem {
  width: 120px;
  height: 44px;
  text-align: center;
  background-color: #FFF7F7;
  line-height: 44px;
  border-radius: 4px;
  font-size: 18px;
  & a {
    color: var(--main-color);
    
  }
}
.applyItem+.applyItem {
  margin-left: 32px;
}

@media (min-width: 576px) {
  .loginForm {
    -o-zoom: 0.6;
    zoom: 0.6
  }
}

@media (min-width: 768px) {
  .loginForm {
    -o-zoom: 0.6;
    zoom: 0.6
  }
}

@media (min-width: 992px) {  // 大于 992  小于 1200 
  .loginForm {
    -o-zoom: 0.7;
    zoom: 0.7
  }
}

@media (min-width: 1200px) {

  .loginForm {
    -o-zoom: 0.8;
    zoom: 0.8
  }
}

@media (min-width: 1400px) {
  .loginForm {
    -o-zoom: 1;
    zoom: 1
  }
}