.aui-margin-l-auto {
	margin-left: auto;
}
.aui-margin-t-4 {
	margin-top: 4px !important;
}
.aui-margin-t-6 {
	margin-top: 6px !important;
}
.aui-margin-t-8 {
	margin-top: 8px !important;
}
.aui-margin-t-10 {
	margin-top: 10px !important;
}
.aui-margin-t-12 {
	margin-top: 12px !important;
}
.aui-margin-t-14 {
	margin-top: 14px !important;
}
.aui-margin-t-16 {
	margin-top: 16px !important;
}
.aui-margin-t-18 {
	margin-top: 18px !important;
}
.aui-margin-t-20 {
	margin-top: 20px !important;
}
.aui-margin-t-22 {
	margin-top: 22px !important;
}
.aui-margin-t-24 {
	margin-top: 24px !important;
}
.aui-margin-t-26 {
	margin-top: 26px !important;
}
.aui-margin-t-28 {
	margin-top: 28px !important;
}
.aui-margin-t-30 {
	margin-top: 30px !important;
}
.aui-margin-t-32 {
	margin-top: 32px !important;
}
.aui-margin-t-34 {
	margin-top: 34px !important;
}
.aui-margin-t-36 {
	margin-top: 36px !important;
}
.aui-margin-t-38 {
	margin-top: 38px !important;
}
.aui-margin-t-40 {
	margin-top: 40px !important;
}
.aui-margin-t-42 {
	margin-top: 42px !important;
}
.aui-margin-t-44 {
	margin-top: 44px !important;
}
.aui-margin-t-46 {
	margin-top: 46px !important;
}
.aui-margin-t-48 {
	margin-top: 48px !important;
}
.aui-margin-l-4 {
	margin-left: 4px !important;
}
.aui-margin-l-6 {
	margin-left: 6px !important;
}
.aui-margin-l-8 {
	margin-left: 8px !important;
}
.aui-margin-l-10 {
	margin-left: 10px !important;
}
.aui-margin-l-12 {
	margin-left: 12px !important;
}
.aui-margin-l-14 {
	margin-left: 14px !important;
}
.aui-margin-l-16 {
	margin-left: 16px !important;
}
.aui-margin-l-18 {
	margin-left: 18px !important;
}
.aui-margin-l-20 {
	margin-left: 20px !important;
}
.aui-margin-l-22 {
	margin-left: 22px !important;
}
.aui-margin-l-24 {
	margin-left: 24px !important;
}
.aui-margin-l-26 {
	margin-left: 26px !important;
}
.aui-margin-l-28 {
	margin-left: 28px !important;
}
.aui-margin-l-30 {
	margin-left: 30px !important;
}
.aui-margin-l-32 {
	margin-left: 32px !important;
}
.aui-margin-l-34 {
	margin-left: 34px !important;
}
.aui-margin-l-36 {
	margin-left: 36px !important;
}
.aui-margin-l-38 {
	margin-left: 38px !important;
}
.aui-margin-l-40 {
	margin-left: 40px !important;
}
.aui-margin-l-42 {
	margin-left: 42px !important;
}
.aui-margin-l-44 {
	margin-left: 44px !important;
}
.aui-margin-l-46 {
	margin-left: 46px !important;
}
.aui-margin-l-48 {
	margin-left: 48px !important;
}
.aui-margin-b-4 {
	margin-bottom: 4px !important;
}
.aui-margin-b-6 {
	margin-bottom: 6px !important;
}
.aui-margin-b-8 {
	margin-bottom: 8px !important;
}
.aui-margin-b-10 {
	margin-bottom: 10px !important;
}
.aui-margin-b-12 {
	margin-bottom: 12px !important;
}
.aui-margin-b-14 {
	margin-bottom: 14px !important;
}
.aui-margin-b-16 {
	margin-bottom: 16px !important;
}
.aui-margin-b-18 {
	margin-bottom: 18px !important;
}
.aui-margin-b-20 {
	margin-bottom: 20px !important;
}
.aui-margin-b-22 {
	margin-bottom: 22px !important;
}
.aui-margin-b-24 {
	margin-bottom: 24px !important;
}
.aui-margin-b-26 {
	margin-bottom: 26px !important;
}
.aui-margin-b-28 {
	margin-bottom: 28px !important;
}
.aui-margin-b-30 {
	margin-bottom: 30px !important;
}
.aui-margin-b-32 {
	margin-bottom: 32px !important;
}
.aui-margin-b-34 {
	margin-bottom: 34px !important;
}
.aui-margin-b-36 {
	margin-bottom: 36px !important;
}
.aui-margin-b-38 {
	margin-bottom: 38px !important;
}
.aui-margin-b-40 {
	margin-bottom: 40px !important;
}
.aui-margin-b-42 {
	margin-bottom: 42px !important;
}
.aui-margin-b-44 {
	margin-bottom: 44px !important;
}
.aui-margin-b-46 {
	margin-bottom: 46px !important;
}
.aui-margin-b-48 {
	margin-bottom: 48px !important;
}
.aui-margin-r-4 {
	margin-right: 4px !important;
}
.aui-margin-r-6 {
	margin-right: 6px !important;
}
.aui-margin-r-8 {
	margin-right: 8px !important;
}
.aui-margin-r-10 {
	margin-right: 10px !important;
}
.aui-margin-r-12 {
	margin-right: 12px !important;
}
.aui-margin-r-14 {
	margin-right: 14px !important;
}
.aui-margin-r-16 {
	margin-right: 16px !important;
}
.aui-margin-r-18 {
	margin-right: 18px !important;
}
.aui-margin-r-20 {
	margin-right: 20px !important;
}
.aui-margin-r-22 {
	margin-right: 22px !important;
}
.aui-margin-r-24 {
	margin-right: 24px !important;
}
.aui-margin-r-26 {
	margin-right: 26px !important;
}
.aui-margin-r-28 {
	margin-right: 28px !important;
}
.aui-margin-r-30 {
	margin-right: 30px !important;
}
.aui-margin-r-32 {
	margin-right: 32px !important;
}
.aui-margin-r-34 {
	margin-right: 34px !important;
}
.aui-margin-r-36 {
	margin-right: 36px !important;
}
.aui-margin-r-38 {
	margin-right: 38px !important;
}
.aui-margin-r-40 {
	margin-right: 40px !important;
}
.aui-margin-r-42 {
	margin-right: 42px !important;
}
.aui-margin-r-44 {
	margin-right: 44px !important;
}
.aui-margin-r-46 {
	margin-right: 46px !important;
}
.aui-margin-r-48 {
	margin-right: 48px !important;
}
.aui-margin-4 {
	margin: 4px !important;
}
.aui-margin-6 {
	margin: 6px !important;
}
.aui-margin-8 {
	margin: 8px !important;
}
.aui-margin-10 {
	margin: 10px !important;
}
.aui-margin-12 {
	margin: 12px !important;
}
.aui-margin-14 {
	margin: 14px !important;
}
.aui-margin-16 {
	margin: 16px !important;
}
.aui-margin-18 {
	margin: 18px !important;
}
.aui-margin-20 {
	margin: 20px !important;
}
.aui-margin-22 {
	margin: 22px !important;
}
.aui-margin-24 {
	margin: 24px !important;
}
.aui-margin-26 {
	margin: 26px !important;
}
.aui-margin-28 {
	margin: 28px !important;
}
.aui-margin-30 {
	margin: 30px !important;
}
.aui-margin-32 {
	margin: 32px !important;
}
.aui-margin-34 {
	margin: 34px !important;
}
.aui-margin-36 {
	margin: 36px !important;
}
.aui-margin-38 {
	margin: 38px !important;
}
.aui-margin-40 {
	margin: 40px !important;
}
.aui-margin-42 {
	margin: 42px !important;
}
.aui-margin-44 {
	margin: 44px !important;
}
.aui-margin-46 {
	margin: 46px !important;
}
.aui-margin-48 {
	margin: 48px !important;
}
