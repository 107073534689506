.aui-padded-t-4 {
  padding-top: 4px !important;
}
.aui-padded-t-6 {
  padding-top: 6px !important;
}
.aui-padded-t-8 {
  padding-top: 8px !important;
}
.aui-padded-t-10 {
  padding-top: 10px !important;
}
.aui-padded-t-12 {
  padding-top: 12px !important;
}
.aui-padded-t-14 {
  padding-top: 14px !important;
}
.aui-padded-t-16 {
  padding-top: 16px !important;
}
.aui-padded-t-18 {
  padding-top: 18px !important;
}
.aui-padded-t-20 {
  padding-top: 20px !important;
}
.aui-padded-t-22 {
  padding-top: 22px !important;
}
.aui-padded-t-24 {
  padding-top: 24px !important;
}
.aui-padded-t-26 {
  padding-top: 26px !important;
}
.aui-padded-t-28 {
  padding-top: 28px !important;
}
.aui-padded-t-30 {
  padding-top: 30px !important;
}
.aui-padded-t-32 {
  padding-top: 32px !important;
}
.aui-padded-t-34 {
  padding-top: 34px !important;
}
.aui-padded-t-36 {
  padding-top: 36px !important;
}
.aui-padded-t-38 {
  padding-top: 38px !important;
}
.aui-padded-t-40 {
  padding-top: 40px !important;
}
.aui-padded-t-42 {
  padding-top: 42px !important;
}
.aui-padded-t-44 {
  padding-top: 44px !important;
}
.aui-padded-t-46 {
  padding-top: 46px !important;
}
.aui-padded-t-48 {
  padding-top: 48px !important;
}
.aui-padded-l-4 {
  padding-left: 4px !important;
}
.aui-padded-l-6 {
  padding-left: 6px !important;
}
.aui-padded-l-8 {
  padding-left: 8px !important;
}
.aui-padded-l-10 {
  padding-left: 10px !important;
}
.aui-padded-l-12 {
  padding-left: 12px !important;
}
.aui-padded-l-14 {
  padding-left: 14px !important;
}
.aui-padded-l-16 {
  padding-left: 16px !important;
}
.aui-padded-l-18 {
  padding-left: 18px !important;
}
.aui-padded-l-20 {
  padding-left: 20px !important;
}
.aui-padded-l-22 {
  padding-left: 22px !important;
}
.aui-padded-l-24 {
  padding-left: 24px !important;
}
.aui-padded-l-26 {
  padding-left: 26px !important;
}
.aui-padded-l-28 {
  padding-left: 28px !important;
}
.aui-padded-l-30 {
  padding-left: 30px !important;
}
.aui-padded-l-32 {
  padding-left: 32px !important;
}
.aui-padded-l-34 {
  padding-left: 34px !important;
}
.aui-padded-l-36 {
  padding-left: 36px !important;
}
.aui-padded-l-38 {
  padding-left: 38px !important;
}
.aui-padded-l-40 {
  padding-left: 40px !important;
}
.aui-padded-l-42 {
  padding-left: 42px !important;
}
.aui-padded-l-44 {
  padding-left: 44px !important;
}
.aui-padded-l-46 {
  padding-left: 46px !important;
}
.aui-padded-l-48 {
  padding-left: 48px !important;
}
.aui-padded-b-4 {
  padding-bottom: 4px !important;
}
.aui-padded-b-6 {
  padding-bottom: 6px !important;
}
.aui-padded-b-8 {
  padding-bottom: 8px !important;
}
.aui-padded-b-10 {
  padding-bottom: 10px !important;
}
.aui-padded-b-12 {
  padding-bottom: 12px !important;
}
.aui-padded-b-14 {
  padding-bottom: 14px !important;
}
.aui-padded-b-15 {
  padding-bottom: 15px !important;
}
.aui-padded-b-16 {
  padding-bottom: 16px !important;
}
.aui-padded-b-18 {
  padding-bottom: 18px !important;
}
.aui-padded-b-20 {
  padding-bottom: 20px !important;
}
.aui-padded-b-22 {
  padding-bottom: 22px !important;
}
.aui-padded-b-24 {
  padding-bottom: 24px !important;
}
.aui-padded-b-26 {
  padding-bottom: 26px !important;
}
.aui-padded-b-28 {
  padding-bottom: 28px !important;
}
.aui-padded-b-30 {
  padding-bottom: 30px !important;
}
.aui-padded-b-32 {
  padding-bottom: 32px !important;
}
.aui-padded-b-34 {
  padding-bottom: 34px !important;
}
.aui-padded-b-36 {
  padding-bottom: 36px !important;
}
.aui-padded-b-38 {
  padding-bottom: 38px !important;
}
.aui-padded-b-40 {
  padding-bottom: 40px !important;
}
.aui-padded-b-42 {
  padding-bottom: 42px !important;
}
.aui-padded-b-44 {
  padding-bottom: 44px !important;
}
.aui-padded-b-46 {
  padding-bottom: 46px !important;
}
.aui-padded-b-48 {
  padding-bottom: 48px !important;
}
.aui-padded-r-4 {
  padding-right: 4px !important;
}
.aui-padded-r-6 {
  padding-right: 6px !important;
}
.aui-padded-r-8 {
  padding-right: 8px !important;
}
.aui-padded-r-10 {
  padding-right: 10px !important;
}
.aui-padded-r-12 {
  padding-right: 12px !important;
}
.aui-padded-r-14 {
  padding-right: 14px !important;
}
.aui-padded-r-16 {
  padding-right: 16px !important;
}
.aui-padded-r-18 {
  padding-right: 18px !important;
}
.aui-padded-r-20 {
  padding-right: 20px !important;
}
.aui-padded-r-22 {
  padding-right: 22px !important;
}
.aui-padded-r-24 {
  padding-right: 24px !important;
}
.aui-padded-r-26 {
  padding-right: 26px !important;
}
.aui-padded-r-28 {
  padding-right: 28px !important;
}
.aui-padded-r-30 {
  padding-right: 30px !important;
}
.aui-padded-r-32 {
  padding-right: 32px !important;
}
.aui-padded-r-34 {
  padding-right: 34px !important;
}
.aui-padded-r-36 {
  padding-right: 36px !important;
}
.aui-padded-r-38 {
  padding-right: 38px !important;
}
.aui-padded-r-40 {
  padding-right: 40px !important;
}
.aui-padded-r-42 {
  padding-right: 42px !important;
}
.aui-padded-r-44 {
  padding-right: 44px !important;
}
.aui-padded-r-46 {
  padding-right: 46px !important;
}
.aui-padded-r-48 {
  padding-right: 48px !important;
}
.aui-padded-4 {
  padding: 4px !important;
}
.aui-padded-6 {
  padding: 6px !important;
}
.aui-padded-8 {
  padding: 8px !important;
}
.aui-padded-10 {
  padding: 10px !important;
}
.aui-padded-12 {
  padding: 12px !important;
}
.aui-padded-14 {
  padding: 14px !important;
}
.aui-padded-16 {
  padding: 16px !important;
}
.aui-padded-18 {
  padding: 18px !important;
}
.aui-padded-20 {
  padding: 20px !important;
}
.aui-padded-22 {
  padding: 22px !important;
}
.aui-padded-24 {
  padding: 24px !important;
}
.aui-padded-26 {
  padding: 26px !important;
}
.aui-padded-28 {
  padding: 28px !important;
}
.aui-padded-30 {
  padding: 30px !important;
}
.aui-padded-32 {
  padding: 32px !important;
}
.aui-padded-34 {
  padding: 34px !important;
}
.aui-padded-36 {
  padding: 36px !important;
}
.aui-padded-38 {
  padding: 38px !important;
}
.aui-padded-40 {
  padding: 40px !important;
}
.aui-padded-42 {
  padding: 42px !important;
}
.aui-padded-44 {
  padding: 44px !important;
}
.aui-padded-46 {
  padding: 46px !important;
}
.aui-padded-48 {
  padding: 48px !important;
}
