.aui-font-weight-500 {
	font-weight: 500;
}
.aui-font-weight-600 {
	font-weight: 600;
}
.aui-font-weight-700 {
	font-weight: 700;
}
.aui-font-weight-800 {
	font-weight: 800;
}
.aui-font-weight-900 {
	font-weight: 900;
}

.aui-font-size-8 {
	font-size: 8px !important;
}
.aui-font-size-10 {
	font-size: 10px !important;
}
.aui-font-size-11 {
	font-size: 11px !important;
}
.aui-font-size-12 {
	font-size: 12px !important;
}
.aui-font-size-13 {
	font-size: 13px !important;
}
.aui-font-size-14 {
	font-size: 14px !important;
}
.aui-font-size-15 {
	font-size: 15px !important;
}
.aui-font-size-16 {
	font-size: 16px !important;
}
.aui-font-size-17 {
	font-size: 17px !important;
}
.aui-font-size-18 {
	font-size: 18px !important;
}
.aui-font-size-19 {
	font-size: 19px !important;
}
.aui-font-size-20 {
	font-size: 20px !important;
}
.aui-font-size-21 {
	font-size: 21px !important;
}
.aui-font-size-22 {
	font-size: 22px !important;
}
.aui-font-size-23 {
	font-size: 23px !important;
}
.aui-font-size-24 {
	font-size: 24px !important;
}
.aui-font-size-25 {
	font-size: 25px !important;
}
.aui-font-size-26 {
	font-size: 26px !important;
}
.aui-font-size-27 {
	font-size: 27px !important;
}
.aui-font-size-28 {
	font-size: 28px !important;
}
.aui-font-size-29 {
	font-size: 29px !important;
}
.aui-font-size-30 {
	font-size: 30px !important;
}
.aui-font-size-31 {
	font-size: 31px !important;
}
.aui-font-size-32 {
	font-size: 32px !important;
}
.aui-font-size-33 {
	font-size: 33px !important;
}
.aui-font-size-34 {
	font-size: 34px !important;
}
.aui-font-size-35 {
	font-size: 35px !important;
}
.aui-font-size-36 {
	font-size: 36px !important;
}
.aui-font-size-37 {
	font-size: 37px !important;
}
.aui-font-size-38 {
	font-size: 38px !important;
}
.aui-font-size-39 {
	font-size: 39px !important;
}
.aui-font-size-40 {
	font-size: 40px !important;
}
.aui-font-size-41 {
	font-size: 41px !important;
}
.aui-font-size-42 {
	font-size: 42px !important;
}
.aui-font-size-43 {
	font-size: 43px !important;
}
.aui-font-size-44 {
	font-size: 44px !important;
}
.aui-font-size-45 {
	font-size: 45px !important;
}
.aui-font-size-46 {
	font-size: 46px !important;
}
.aui-font-size-47 {
	font-size: 47px !important;
}
.aui-font-size-48 {
	font-size: 48px !important;
}
.aui-font-size-49 {
	font-size: 49px !important;
}
.aui-font-size-50 {
	font-size: 50px !important;
}
.aui-font-size-51 {
	font-size: 51px !important;
}
.aui-font-size-52 {
	font-size: 52px !important;
}
.aui-font-size-53 {
	font-size: 53px !important;
}
.aui-font-size-54 {
	font-size: 54px !important;
}

.aui-font-size-80 {
	font-size: 80px !important;
}
.aui-font-size-82 {
	font-size: 82px !important;
}
.aui-font-size-146 {
	font-size: 146px !important;
}

.aui-font-size-106 {
	font-size: 106px !important;
}
