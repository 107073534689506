.dg__card {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
}
.title__box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 10px;
}
.title {
  box-sizing: border-box;
  font-size: 18px;
  color: #303133;
  position: relative;
  text-align: left;
  margin-right: 20px;
  padding-left: 10px;
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 16px;
    background: var(--main-color);
    position: absolute;
    left: 0px;
    top: 5px;
  }
}
.amount__color {
  color: var(--amount-color);
}
.opers {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 10px;
  button {
    width: 90px !important;
  }
  button + button {
    margin-left: 10px;
  }
}
.dg__card + .dg__card {
  margin-top: 4px;
}
.imageBox {
  display: flex;
  column-gap: 10px;
}
.suffixBox {
  font-size: 12px;
  box-sizing: border-box;
  padding: 2px 0px;
  color: #909399;
}
.filesItem {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding-top: 10px;
  font-size: 14px;
  justify-content: right;
  flex-wrap: wrap;
}
.filesLable {
  width: 200px;
  text-align: right;
  color: #788593;
}
.filesValues {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.filesValue {
  width: calc(100% - 200px);
  box-sizing: border-box;
  padding: 0px 0px 12px 5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F2F2F2;
}
.filesName {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  flex: 1;
}
.filesValue + .filesValue {
  margin-top: 8px;
}
.filesActive {
  color: var(--main-color);
}
.filesActiveTime {
  color: #192530;
}
.filesEnable {
  color: #788593;
  text-decoration: line-through;
}
.filesEnable_time {
  color: #788593;
  text-decoration: underline;
}

.reparation_box {
  width: 100%;
  margin-top: 12px;
  background-color: #FAFAFA;
  box-sizing: border-box;
  padding: 12px 0px;
}