.header {

  width: 100%;
  height: 60px;
  background-color: #fff;
  // border-bottom: 1px solid red;

  &.active {
    background: #fff;
  }

}

.headerH {
  height: 60px;
}

.logo {
  // width: 430px;
  height: 60px;
}
.notifiIcon {
  width: 32px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
  }
}
.notification {
  p {
    line-height: 22px;
  }
}
.notifiTitle {
  color: #FF0000;
  font-size: 14px;
  margin: 8px 0px;
}


// body{
//   background: red;
// }