.banner_wrap {
  width: 100%;
  height: calc(100% - 56px);
  >div {
    height: 100%;
  }
}
.bgWrap {
  // background-image: url('../../../assets/img/login_bg.png');
  // background-repeat: no-repeat;
  // background-position: 100% 100%;
  height: 886px;
  position: relative;
}
.bgBox {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  & img {
    width: 100%;
    height: 100%;
  }
}

.banner_carousel {
  img {
    height: 85vh;
    width: 100%;
  }

}


.imgCode {
  width: 100%;
  height: 40px;
}

.from {
  width: 590px;
  background: #fff;
  border-radius: 4px;
  min-height: 200px;
  padding: 0 100px;
  padding-bottom: 70px;
}

.login_button {
  width: 100%;
  background-color: #C8010B !important;
  border-color: #C8010B !important;
}

.top_25 {
  margin-top: -20px !important;
}