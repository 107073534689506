#register {
  height: 100vh;
  background-image: url('./assets/img/login_bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.form {
  background: #fff;
  margin: auto;
  width: 552px;
  border-radius: 4px;
  padding: 0 80px;
}


@media (min-width: 576px) {
  .form {
    zoom: 0.6
  }
}

@media (min-width: 768px) {
  .form {
    zoom: 0.6
  }
}

@media (min-width: 992px) {
  .form {
    zoom: 0.7
  }
}

@media (min-width: 1200px) {

  .form {
    zoom: 0.8
  }
}

@media (min-width: 1400px) {
  .form {
    zoom: 1
  }
}


.imgCode {
  width: 100%;
  height: 40px;
}

.register_button {
  width: 100%;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.top_25 {
  margin-top: -20px !important;
}