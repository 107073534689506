:root {
	--main-color: #C8010B;
	--amount-color: #FF0000;
}

* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}
/* url('//at.alicdn.com/t/c/font_3646812_wxbyl74gczs.woff2?t=1663127493363') format('woff2'),
       url('//at.alicdn.com/t/c/font_3646812_wxbyl74gczs.woff?t=1663127493363') format('woff'),
       url('//at.alicdn.com/t/c/font_3646812_wxbyl74gczs.ttf?t=1663127493363') format('truetype'); */

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
	font-family: "iconfont"; /* Project id 3646812 */
	src: url("../iconfont/iconfont.ttf") format("truetype"), url("../iconfont/iconfont.woff2") format("woff2"), url("../iconfont/iconfont.woff") format("woff");
}
.icon {
	font-family: "iconfont" !important;
	font-size: 12px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	cursor: pointer;
}

.icon-js {
	width: 12px;
	height: 12px;
	vertical-align: -2px;
	fill: currentColor;
	overflow: hidden;
}

/* 修改原生的滚动条 */
::-webkit-scrollbar {
	/* 血槽宽度 */
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	/* 拖动条 */
	background: rgba(0, 0, 0, 0.3);
	border-radius: 6px;
}
::-webkit-scrollbar-track {
	/* 背景槽 */
	background: #ddd;
	border-radius: 6px;
}
img,
input {
	border: none;
	vertical-align: middle;
	outline: none;
}
:focus {
	outline: 0;
}
body {
	/* font-family: Tahoma, Arial, Helvetica, "Microsoft YaHei","Microsoft YaHei Regular"; */
	font-family: "Microsoft YaHei Regular", "Microsoft YaHei";
	font-size: 12px;
	text-align: left;
	background: #F7F8FC;
	color: #191a24;
}

ul,
ol {
	list-style-type: none;
}
body p {
	margin: 0;
}
th,
td,
input {
	font-size: 12px;
}
h3 {
	font-size: 14px;
}
button {
	border: none;
	cursor: pointer;
	font-size: 12px;
	background-color: transparent;
}
select {
	border-width: 1px;
	_zoom: 1;
	border-style: solid;
	padding-top: 2px;
	font-size: 12px;
}

button,
select {
	text-transform: none;
}
button,
input,
select {
	color: inherit;
}
img {
	max-width: 100%;
}
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	visibility: hidden;
	clear: both;
}
.clearfix {
	zoom: 1;
}
a:link,
a:visited {
	text-decoration: none;
	/* color: #333; */
}
a:hover,
a:active {
	text-decoration: underline;
	color: #0a78e5;
}
/* 自动换行 */
.aui-break-word {
	word-wrap: break-word;
}
/* 强制不换行: */
.aui-nowrap {
	white-space: nowrap;
}
/* 强制英文单词断行 */
.aui-break-all {
	word-break: break-all;
}

.aui-clear:after {
	display: block;
	clear: both;
	content: "";
	visibility: hidden;
	height: 0;
}
.aui-clear {
	zoom: 1;
}
.aui-color-main {
	color: var(--main-color);
}

.aui-color-84 {
	color: #848484 !important;
}

.aui-color-32 {
	color: #323232 !important;
}

.aui-color-33 {
	color: #333333 !important;
}
.aui-color-cc {
	color: #cccccc !important;
}
.aui-191a24 {
	color: #191a24;
}
.aui-color-ff {
	color: #ffffff !important;
}
.aui-bg-white {
	background: white !important;
}
.aui-bg-gray {
	background: #f5f5f5;
}
.aui-666666 {
	color: #666666;
}
.aui-999CA1{
	color: #999CA1;
}
.aui-333333 {
	color: #333333;
}
.aui-303133 {
	color: #303133;
}
.aui-909399 {
	color: #909399;
}
.aui-b1b1b1{
	color: #b1b1b1;
}
.aui-A1A1A1 {
	color: #a1a1a1;
}
.aui-B8BEC5 {
	color: #b8bec5;
}
.aui-999999 {
	color: #999999;
}
.aui-191A24 {
	color: #191a24;
}
.aui-757575 {
	color: #757575;
}
.aui-C8010B {
	color: #C8010B !important;
}
.aui-000000 {
	color: #000000;
}
.aui-192530 {
	color: #192530;
}
.aui-788593 {
	color: #788593;
}

.aui-FF4D4F {
	color: #ff4d4f;
}
.aui-F33F41 {
	color: #f33f41;
}
.aui-606266 {
	color: #606266;
}
.aui-C8010B {
	color: var(--main-color) !important;
}
.aui-bg-C8010B {
	background-color: var(--main-color) !important;
}
.w-1200 {
	width: 1200px;
}
.w-150 {
	width: 150px;
}
.w-160 {
	width: 160px;
}
.w-170 {
	width: 170px !important;
}
.w-180 {
	width: 180px !important;
}
.w-200 {
	width: 200px !important;
}
.w-250 {
	width: 250px;
}
.w-270 {
	width: 270px;
}
.w-280 {
	width: 280px;
}
.w-300 {
	width: 300px;
}
.w-500 {
	width: 500px;
}
.w-1000{
	width: 800px;
	margin: 0 auto;
}
.w-full{
	width: 100%;
}
.aui-margin-auto {
	margin: 0 auto;
}
.aui-margin-b-0 {
	margin-bottom: 0 !important;
}

.aui-rel {
	position: relative;
}
.aui-pointer {
	cursor: pointer;
}
.aui-link {
	cursor: pointer;
	color: var(--main-color);
}
.aui-del {
	color: #f33f41;
	cursor: pointer;
}
/*文字对齐*/
.aui-text-left {
	text-align: left !important;
}
.aui-text-center {
	text-align: center !important;
}
.aui-text-justify {
	text-align: justify !important;
}
.aui-text-right {
	text-align: right !important;
}

/*对齐,显示，隐藏等*/
.aui-pull-right {
	float: right !important;
}
.aui-pull-left {
	float: left !important;
}
.aui-hide {
	display: none !important;
}
.aui-show {
	display: block !important;
}

.aui-line-24 {
	line-height: 24px;
}
.aui-line-25 {
	line-height: 25px;
}
.aui-line-26 {
	line-height: 26px;
}
.aui-line-28 {
	line-height: 28px;
}

/* // 自动换行 */
.aui-word-normal {
	word-wrap: break-word;
	word-break: normal;
}
.aui-word-nowrap {
	white-space: nowrap;
}
.aui-inline {
	display: inline-block;
}

/*自动隐藏文字*/
.aui-ellipsis-1 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.aui-ellipsis {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	word-break: break-all;
	white-space: normal !important;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.aui-ellipsis-2 {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	word-break: break-all;
	white-space: normal !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.aui-row {
	overflow: hidden;
	margin: 0;
}

[class="aui-bor*"] {
	position: relative;
}
.aui-bor-b::after {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: #d8d8d8;
	transform: scale(1, 0.5);
}
.aui-bor-t::after {
	content: "";
	display: block;
	position: absolute;
	top: 0%;
	left: 0;
	width: 100%;
	height: 1px;
	background: #d8d8d8;
	transform: scale(1, 0.5);
}
.aui-bor-l::after {
	content: "";
	display: block;
	position: absolute;
	top: 0%;
	left: 0;
	width: 1px;
	height: 100%;
	background: #d8d8d8;
	transform: scale(0.5, 1);
}
.aui-bor-r::after {
	content: "";
	display: block;
	position: absolute;
	top: 10%;
	right: 0;
	width: 80%;
	height: 1px;
	background: #d8d8d8;
	transform: scale(0.5, 1);
}
