.aui-flex {
	display: flex;
}
.aui-inline-flex {
	display: inline-flex;
}
.aui-wrap {
	flex-flow: wrap;
}

.aui-row-between {
	/* 两端对齐，项目之间的间隔都相等。 */
	justify-content: space-between;
}
.aui-row-around {
	/* 每个项目两侧的间隔相等。所以，项目之间的间隔比项目与边框的间隔大一倍。 */
	justify-content: space-around;
}
.aui-row-end {
	justify-content: flex-end;
}
.aui-row-center {
	/* 居中 */
	justify-content: center;
}

/* align-items属性定义项目在交叉轴上如何对齐。 */
.aui-col-start {
	align-items: flex-start;
}

.aui-col-end {
	align-items: flex-end;
}

.aui-col-center {
	align-items: center;
}
.aui-col-stretch {
	align-items: stretch;
}
.aui-col-baseline {
	/* 文字对齐 */
	align-items: baseline;
}

/* align-content属性定义了多根轴线的对齐方式 */
.aui-cols-center {
	align-content: center;
}
.aui-cols-around {
	align-content: space-around;
}
.aui-cols-between {
	align-content: space-between;
}
.aui-row-right {
	margin-left: auto !important;
	margin-right: 0 !important;
}
.aui-item {
	flex: 1;
}
