.wrap {
  min-height: 100vh;
  background: #f7f8fc;
}

.sider {
  width: 200px;
  min-height: 80vh;
  background: var(--main-color);
  color: #fff;
  padding: 0 10px;

  .meunTop {
    >p {
      line-height: 50px;
      height: 50px;
    }

    li {
      line-height: 40px;
      height: 40px;
      padding: 0px 5px 0px 15px;
      border-radius: 4px;
      margin: 7px 0;

      &.active {
        background: #D84D54;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .normalMenu {
      transform: rotateX(-90deg);
	    transform-origin: center top;
	    transition: transform 0.3s ease;
    }

    .activeMenu {
      max-height: fit-content;
      overflow: hidden;
      transform: rotateX(0deg);
    }
    .menu {
      max-height: 0px;
      overflow: hidden;
    }
  }

  a {
    color: #fff;
    font-size: 14px;

    &:active {
      color: #fff;
    }

    &:hover {
      color: #fff;
    }

    width: 100%;
    height: 100%;
    display: inline-block;
  }

  li {
    // line-height: 44px;
    // padding-left: 50px;
    // font-size: 14px;
    // margin-bottom: 5px;
    cursor: pointer;
    // a{
    //   width: 100%;
    //   height: 100%;
    //   display: inline-block;
    // }
    // &.active ,  &:hover{
    //   background: #eaf4ff;
    //   a {
    //     color: #177AE6;
    //   }
    // }

  }
}



.content {
  width: 985px;
  // background: #fff;
}

.contact_wrap {
  position: fixed;
  top: 40%;
  right: 15px;

  &:hover {
    .contact_info {
      right: 56px;
    }
  }
}

.contact {
  background: var(--main-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;

  cursor: pointer;
  position: relative;
  z-index: 10;
  display: flex;

  i {
    margin: auto;
  }
}

.contact_txt {
  width: 22px;
  margin: 0 auto;
  word-wrap: break-word;
  /*英文的时候需要加上这句，自动换行*/
  word-break: break-all;
  color: #fff;
  text-align: center;
  font-size: 16px;

}

.contact_info {
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition: all 0.3s;
  top: -35px;
  right: -340px;
  width: 260px;
  padding: 38px 20px;
  z-index: 9;
  box-shadow: 0px 2px 6px 0px rgba(197, 197, 197, 0.22);
}
.menuBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}